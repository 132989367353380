import React from 'react';
import styled from 'styled-components';
import phoneImg from '../../images/iphone.png';
import cm1 from '../../images/cm1.png';
import cm2 from '../../images/cm2.png';
import cm3 from '../../images/cm3.png';

const Phones = styled.div`
    padding: 0 15px;
    max-width: 1600px;
    margin: 0 auto;

    @media (min-width: 768px) {
        padding: 0 100px;
    }

    .phones-wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .phones-block {
        flex: 0 0 calc(50% - 30px);
        margin: 0 15px;
        display: block;
        position: relative;
        @media (min-width: 768px) {
            flex: 0 0 calc(33.33333% - 30px);
        }

        @media (min-width: 992px) {
            flex: 0 0 calc(33.33333% - 60px);
            margin: 0 30px;
        }

        @media (min-width: 1200px) {
            flex: 0 0 calc(33.33333% - 100px);
            margin: 0 50px;
        }

        &:last-child {
            @media (max-width: 768px) {
                margin-top: 30px;
            }
        }

        img {
            width: 100%;
            display: block;
        }
    }
`;

const phonesBlock = ({ images }) => (
  <Phones>
    {images && Boolean(images.length) && (
      <div className="phones-wrap">
        {images.map((imageBlock, idx) => (
          <div key={idx} className="phones-block">
            <img src={imageBlock.image.source_url} alt={imageBlock.image.alt_text} />
          </div>
        ))}
      </div>
    )}
  </Phones>
);

export default phonesBlock;
