import React from 'react';
import styled from 'styled-components';

const Title = styled.div`
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    .title-top {
        color: #737373;
    }

    .title-main {
        color: white;
        margin-top: 10px;
    }
`;

const titleBlock = ({ centered_text_title, centered_text }) => (
  <div className="container">
    <Title>
      {centered_text_title && <p className="title-top">{centered_text_title}</p>}
      {centered_text && <h3 className="title-main">{centered_text}</h3>}
    </Title>
  </div>
);

export default titleBlock;
