import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Controller, Scene } from 'react-scrollmagic';

import Seo from '../components/seo';
import ProjectBanner from '../components/project/projectBanner';
import IntroBlock from '../components/project/introBlock';
import ImageBlock from '../components/project/imageBlock';
import TitleBlock from '../components/project/titleBlock';
import PhonesBlock from '../components/project/phonesBlock';
import QuoteBlock from '../components/project/quoteBlock';
import VideoBlock from '../components/project/videoBlock';
import ProjectLink from '../components/project/projectLink';

const Project = styled.div`
    .container {
        padding: 0 15px;
        max-width: 1000px;
        margin: 0 auto;
        @media (min-width: 768px) {
            max-width: 700px;
        }

        @media (min-width: 950px) {
            max-width: 900px;
        }

        @media (min-width: 1050) {
            max-width: 100px;
        }
    }
`;

const FlexibleBlocks = styled.div`
    transition: all 300ms;
    transition-delay: 1000ms;
    opacity: ${props => (props.pageIn ? 1 : 0)};
    .flexibleblock {
        opacity: 0;
        transform: translateY(-30px);
        transition: all 600ms ${props => props.theme.cubic};
        &.__in {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

const Spacer = styled.div`
    width: 100%;
    display: block;
    position: relative;
    height: ${props => props.mobileMargin};

    @media (min-width: 768px) {
        height: ${props => props.tabletMargin};
    }

    @media (min-width: 992px) {
        height: ${props => props.desktopMargin};
    }
`;

class ProjectPage extends Component {
    state = {
        pageIn: false,
    };

    componentDidMount() {
        this.setState({
            pageIn: true,
        });
    }

    getNextProject = (allProjects, currentID) => {
        // loop over all projects and check if the current ID has one before or after
        let project = false;
        allProjects.edges.forEach((edge, i) => {
            if (edge.node.wordpress_id === currentID && i + 1 < allProjects.edges.length) {
                project = allProjects.edges[i + 1];
            }
        });

        return project;
    };

    getPreviousProject = (allProjects, currentID) => {
        // loop over all projects and check if the current ID has one before or after
        let project = false;
        allProjects.edges.forEach((edge, i) => {
            if (edge.node.wordpress_id === currentID && i !== 0) {
                project = allProjects.edges[i - 1];
            }
        });

        return project;
    };

    renderContentBlock = (type, data) => {
        console.log(data);
        switch (type) {
            case 'WordPressAcf_introduction_block':
                return <IntroBlock {...data} />;
            case 'WordPressAcf_standard_image_block':
                return <ImageBlock fullWidth={false} {...data} />;
            case 'WordPressAcf_centered_text':
                return <TitleBlock {...data} />;
            case 'WordPressAcf_full_width_image_block':
                return <ImageBlock fullWidth {...data} />;
            case 'WordPressAcf_3_col_image_block':
                return <PhonesBlock {...data} />;
            case 'WordPressAcf_3_col_image_block_2':
                return <PhonesBlock {...data} />;
            case 'WordPressAcf_spacer':
                return (
                    <Spacer
                        mobileMargin={data.overwrite_mobile_margin ? `${data.overwrite_mobile_margin}px` : '40px'}
                        tabletMargin={data.overwrite_tablet_margin ? `${data.overwrite_tablet_margin}px` : '90px'}
                        desktopMargin={data.overwrite_desktop_margin ? `${data.overwrite_desktop_margin}px` : '135px'}
                    />
                );
            case 'WordPressAcf_quote_block':
                return <QuoteBlock {...data} />;
            case 'WordPressAcf_video_block':
                return <VideoBlock {...data} />;
            default:
                break;
        }
    };

    render() {
        const { data } = this.props;
        const { allWordpressAcfOptions, wordpressWpProject, allWordpressWpProject } = data;
        const previousProject = this.getPreviousProject(allWordpressWpProject, this.props.pathContext.id);
        const nextProject = this.getNextProject(allWordpressWpProject, this.props.pathContext.id);

        return (
            <>
                <Seo title={wordpressWpProject.yoast.title || wordpressWpProject.title} description={wordpressWpProject.yoast.metadesc} />
                <Project>
                    <ProjectBanner
                        bannerImage={wordpressWpProject.acf.project_banner_image}
                        bannerTitle={wordpressWpProject.acf.project_banner_title}
                        pageIn={this.state.pageIn}
                        previousProject={previousProject}
                        nextProject={nextProject}
                    />
                    {wordpressWpProject.acf.project_content_project && (
                        <FlexibleBlocks id="flexibleBlocks" pageIn={this.state.pageIn}>
                            <Controller>
                                <Spacer mobileMargin="40px" tabletMargin="90px" desktopMargin="135px" />
                                {wordpressWpProject.acf.project_content_project.map(contentBlock => (
                                    <Scene offset="-300" classToggle="__in" key={contentBlock.id}>
                                        <div className="flexibleblock">
                                            {this.renderContentBlock(contentBlock.__typename, contentBlock)}
                                        </div>
                                    </Scene>
                                ))}
                            </Controller>
                        </FlexibleBlocks>
                    )}

                    {(nextProject || previousProject) && (
                        <div className="project-footer">
                            <ProjectLink
                                project={nextProject || previousProject}
                                options={allWordpressAcfOptions.edges[0].node.options}
                            />
                        </div>
                    )}
                </Project>
            </>
        );
    }
}

export const query = graphql`
    query($id: Int) {
        allWordpressAcfOptions {
            edges {
                node {
                    options {
                        instagram_link
                        instagram_handle
                        email_address
                    }
                }
            }
        }

        allWordpressWpProject(sort: { fields: menu_order, order: ASC }) {
					edges {
						node {				
							title				
							wordpress_id
							path
							acf {
								project_navigation_image {
									source_url
									alt_text
								}
							}
						}
					}
        }

        wordpressWpProject(wordpress_id: { eq: $id }) {
            title
            slug
            path
            link
						yoast {
							title
							metadesc
						}
            acf {
                project_banner_title
                project_slider_image {
                    source_url
                    alt_text
                }
                project_navigation_image {
                    source_url
                    alt_text
                }
                project_banner_image {
                    source_url
                    alt_text
                }
                project_content_project {
                    __typename
                    ... on WordPressAcf_introduction_block {
                        id
                        block_content
                        block_side_details {
                            detail_title
                            detail_text
                        }
                    }
                    ... on WordPressAcf_standard_image_block {
                        id
                        image {
                            source_url
                            alt_text
                        }
                    }
                    ... on WordPressAcf_centered_text {
                        id
                        centered_text_title
                        centered_text
                    }
                    ... on WordPressAcf_full_width_image_block {
                        id
                        image {
                            source_url
                            alt_text
                        }
                    }
                    ... on WordPressAcf_3_col_image_block {
                        id
                        images {   
                           image {
                            source_url
                           }                          
                        }
                    }
                    ... on WordPressAcf_spacer {
                        id
                        overwrite_mobile_margin
                        overwrite_tablet_margin
                        overwrite_desktop_margin
                    }
                    ... on WordPressAcf_quote_block {
                        id
                        quote_text
                        quote_author
                    }
                    ... on WordPressAcf_video_block {
                        id
                        video {
                            source_url
                        }
												mobile_gif { 
													source_url
												}
                    }
                }
            }
        }
    }
`;

export default ProjectPage;
