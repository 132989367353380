/* eslint-disable react/no-find-dom-node */
import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import useWindowScrollPosition from '@rehooks/window-scroll-position';
import useWindowSize from '@rehooks/window-size';

const Video = styled.div`
	width: 100%;
	box-shadow: 0px 0px 26px rgba(0,0,0,0.2);
	video,
	.video-gif {
		border-radius: 10px;
	}
`;

const videoBlock = ({ video: { source_url: videoSourceUrl }, mobile_gif: { source_url: gifSourceUrl } }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoElement = useRef(null);

  const { innerWidth } = useWindowSize();

  if (videoElement.current) {
    const { y: videoYPosition } = (ReactDOM
      .findDOMNode(videoElement.current)
      .getBoundingClientRect());

    if (videoYPosition < 1000 && !isVideoPlaying) {
      setIsVideoPlaying(true);
    }

    if (videoYPosition > 1000 && isVideoPlaying) {
      setIsVideoPlaying(false);
    }
  }

  useWindowScrollPosition();

  return (
    <div className="container">
      <Video ref={videoElement}>
        {innerWidth < 768 ? (
          <img src={gifSourceUrl} alt="" className="video-gif" />
        ) : (
          <ReactPlayer url={videoSourceUrl} muted volume={0} loop playing={isVideoPlaying} width="100%" height="auto" />
        )}
      </Video>
    </div>
  );
};

export default videoBlock;
