import React from 'react';
import styled from 'styled-components';

const Quote = styled.div`
    .quote-text {
        color: white;
        font-family: ${props => props.theme.titleFont};
        font-size: 1.2rem;
        line-height: 1.6;
        border-left: 1px solid white;
        padding-left: 30px;
        @media (min-width: 992px) {
            font-size: 1.4rem;
            line-height: 1.8;
            padding-left: 32px;
        }
    }

    .quote-author {
        color: #737373;
        padding-left: 30px;
        margin-top: 25px;
        @media (min-width: 992px) {
            margin-top: 30px;
            padding-left: 32px;
        }
    }
`;

const quoteBlock = ({ quote_text, quote_author }) => (
  <div className="container">
    <Quote>
      {quote_text && <p className="quote-text">{quote_text}</p>}
      {quote_author && <p className="quote-author">{quote_author}</p>}
    </Quote>
  </div>
);

export default quoteBlock;
