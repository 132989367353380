import React from 'react';
import styled from 'styled-components';

const Intro = styled.div`
    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
    }

    .intro-text {
        color: white;
        max-width: 90%;
        margin: 0 auto;
        @media (max-width: 768px) {
            text-align: center;
        }
        @media (min-width: 768px) {
            flex: 0 0 400px;
            margin: 0;
        }
        @media (min-width: 992px) {
            flex: 0 0 590px;
        }
    }

    .introblock-details {
        margin-top: 23px;
        @media (min-width: 768px) {
            margin: 0;
        }
    }

    .introblock-detail {
        &:not(:last-child) {
            margin-bottom: 23px;
        }
    }
`;

const DetailBlock = styled.div`
    @media (max-width: 768px) {
        text-align: center;
    }
    .detailblock-top {
        color: #737373;
    }

    .detailblock-title {
        color: white;
        font-family: ${props => props.theme.titleFont};
        font-size: 1.35rem;
    }
`;

const introBlock = ({ block_content, block_side_details }) => (
  <div className="container">
    <Intro>
      {block_content && <p className="intro-text">{block_content}</p>}

      {block_side_details && (
        <div className="introblock-details">
          {block_side_details.map((detail, idx) => (
            <div className="introblock-detail" key={idx}>
              <DetailBlock>
                {detail.detail_title && <p className="detailblock-top">{detail.detail_title}</p>}
                {detail.detail_text && <h3 className="detailblock-title">{detail.detail_text}</h3>}
              </DetailBlock>
            </div>
          ))}
        </div>
      )}
    </Intro>
  </div>
);

export default introBlock;
