import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

const ContactBlock = styled.div`
    display: flex;
    align-items: ${props => (props.withText ? 'flex-start' : 'center')};
    justify-content: center;
    flex-direction: ${props => (props.stacked ? 'column' : 'row')};
    width: 100%;
    opacity: ${props => (props.pageIn ? 1 : 0)};
    transform: ${props => (props.pageIn ? 'translateY(0)' : 'translateY(-30px)')};
    transition: all 300ms;

    @media (min-width: 768px) {
        justify-content: flex-start;
    }

    a {
        display: ${props => (props.withText ? 'flex' : 'block')};
        align-items: center;

        &:first-child {
            margin: ${props => (props.stacked ? '0 0 20px 0' : '0 25px 0 0')};
        }

        img {
            display: block;
        }

        &:hover,
        &:focus {
            svg {
                g path {
                    fill: white;
                }
            }

            .contactblock-title {
                &::after {
                    width: 100%;
                    right: auto;
                    left: 0;
                }
            }
        }
    }

    .contactblock-title {
        color: white;
        margin-left: 20px;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0;
            height: 1px;
            background: white;
            transition: all 300ms ${props => props.theme.cubic};
        }
    }

    .instagram {
        margin-left: ${props => (props.stacked ? '1px' : '0')};
        &:hover,
        &:focus {
            g path {
                fill: white;
            }
        }
        g path {
            transition: all 300ms;
            fill: #737373;
        }
    }

    .email {
        &:hover,
        &:focus {
            g path {
                fill: white;
            }
        }
        g path {
            transition: all 300ms;
            fill: #737373;
        }
    }
`;

const ContactButtons = ({ withText, stacked, options }) => {
  const [pageIn, setPageIn] = useState(false);
  setTimeout(() => {
    setPageIn(true);
  }, 300);
  return (
    <ContactBlock pageIn={pageIn} withText={withText} stacked={stacked}>
      {options.instagram_link && (
      <a href={options.instagram_link}>
        <svg
          className="instagram"
          width={stacked ? '25px' : '23px'}
          height={stacked ? '25px' : '23px'}
          viewBox="0 0 29 29"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="insta" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M14.462,0 C10.534,0 10.042,0.017 8.499,0.087 C6.96,0.157 5.909,0.402 4.989,0.759 C4.038,1.129 3.232,1.624 2.428,2.427 C1.623,3.231 1.129,4.038 0.76,4.989 C0.402,5.909 0.157,6.96 0.087,8.5 C0.017,10.042 0,10.534 0,14.462 C0,18.39 0.017,18.882 0.087,20.424 C0.157,21.964 0.402,23.015 0.76,23.935 C1.129,24.886 1.623,25.692 2.428,26.497 C3.232,27.3 4.038,27.795 4.989,28.165 C5.909,28.522 6.96,28.767 8.499,28.836 C10.042,28.907 10.534,28.924 14.462,28.924 C18.39,28.924 18.882,28.907 20.425,28.836 C21.964,28.767 23.015,28.522 23.935,28.165 C24.886,27.795 25.692,27.3 26.496,26.497 C27.301,25.692 27.795,24.886 28.164,23.935 C28.522,23.015 28.767,21.964 28.837,20.424 C28.907,18.882 28.924,18.39 28.924,14.462 C28.924,10.534 28.907,10.042 28.837,8.5 C28.767,6.96 28.522,5.909 28.164,4.989 C27.795,4.038 27.301,3.231 26.496,2.427 C25.692,1.624 24.886,1.129 23.935,0.759 C23.015,0.402 21.964,0.157 20.425,0.087 C18.882,0.017 18.39,0 14.462,0 M14.462,2.606 C18.323,2.606 18.78,2.621 20.306,2.69 C21.716,2.754 22.482,2.99 22.991,3.188 C23.666,3.451 24.148,3.764 24.654,4.27 C25.16,4.776 25.474,5.258 25.735,5.933 C25.934,6.442 26.169,7.208 26.233,8.618 C26.303,10.143 26.318,10.601 26.318,14.462 C26.318,18.323 26.303,18.781 26.233,20.306 C26.169,21.716 25.934,22.481 25.735,22.991 C25.474,23.666 25.16,24.148 24.654,24.654 C24.148,25.16 23.666,25.473 22.991,25.736 C22.482,25.934 21.716,26.169 20.306,26.233 C18.781,26.303 18.323,26.318 14.462,26.318 C10.601,26.318 10.143,26.303 8.618,26.233 C7.208,26.169 6.442,25.934 5.933,25.736 C5.258,25.473 4.776,25.16 4.27,24.654 C3.764,24.148 3.45,23.666 3.189,22.991 C2.99,22.481 2.755,21.716 2.691,20.306 C2.621,18.781 2.606,18.323 2.606,14.462 C2.606,10.601 2.621,10.143 2.691,8.618 C2.755,7.208 2.99,6.442 3.189,5.933 C3.45,5.258 3.764,4.776 4.27,4.27 C4.776,3.764 5.258,3.451 5.933,3.188 C6.442,2.99 7.208,2.754 8.618,2.69 C10.144,2.621 10.601,2.606 14.462,2.606"
              id="Fill-9"
              fill="#777777"
            />
            <path
              d="M14.427,19.247 C11.765,19.247 9.607,17.088 9.607,14.426 C9.607,11.764 11.765,9.605 14.427,9.605 C17.089,9.605 19.247,11.764 19.247,14.426 C19.247,17.088 17.089,19.247 14.427,19.247 M14.427,7 C10.325,7 7,10.325 7,14.426 C7,18.528 10.325,21.852 14.427,21.852 C18.529,21.852 21.854,18.528 21.854,14.426 C21.854,10.325 18.529,7 14.427,7"
              id="Fill-10"
              fill="#777777"
            />
            <path
              d="M23.471,6.735 C23.471,7.693 22.694,8.47 21.736,8.47 C20.778,8.47 20,7.693 20,6.735 C20,5.777 20.778,5 21.736,5 C22.694,5 23.471,5.777 23.471,6.735"
              id="Fill-11"
              fill="#777777"
            />
          </g>
        </svg>
        {withText && options.instagram_handle && (
        <p className="contactblock-title">{options.instagram_handle}</p>
        )}
      </a>
      )}
      {options.email_address && (
      <a href={`mailto:${options.email_address}`}>
        <svg
          className="email"
          width={stacked ? '27px' : '29px'}
          height={stacked ? '22px' : '23px'}
          viewBox="0 0 100 69"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs />
          <g id="Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M3.0312,0 L96.5932,0 C98.4877,0 100.0034,1.1367 100.0034,3.0312 L100.0034,65.9102 C100.0034,67.8047 98.4878,68.9414 96.5932,68.9414 L3.0312,68.9414 C1.5156,68.9414 0,67.8047 0,65.9102 L0,3.0312 C0,1.1367 1.5156,0 3.0312,0 Z M28.4102,29.547 L6.4412,9.852 L6.4412,57.2 L28.4102,29.547 Z M32.9571,33.715 L9.8521,62.883 L90.1571,62.883 L66.6731,33.715 L56.8254,42.8048 C52.6574,46.215 46.9777,46.215 43.1884,42.8048 L32.9614,33.715 L32.9571,33.715 Z M71.5941,29.547 L93.5631,57.199 L93.5631,10.23 L71.5941,29.547 Z M88.6411,6.442 L11.3681,6.442 L47.3521,37.88 C48.8677,39.3956 51.1412,39.3956 52.6568,37.88 L88.6411,6.442 Z"
              id="Fill-1"
              fill="#000000"
            />
          </g>
        </svg>
        {withText && (
        <p className="contactblock-title">{options.email_address}</p>
        )}
      </a>
      )}
    </ContactBlock>
  );
};
export const query = graphql`
    query {
        allWordpressAcfOptions {
            edges {
                node {
                    options {
                        instagram_link
                        instagram_handle
                        email_address
                    }
                }
            }
        }
    }
`;

export default ContactButtons;
