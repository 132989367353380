import React from 'react';
import styled from 'styled-components';

const ImageWrap = styled.div`
    box-shadow: ${props => (props.fullWidth ? 'none' : '0px 0px 26px rgba(0, 0, 0, 0.2)')};
    width: 100%;
    img {
        width: 100%;
    }
`;

const imageBlock = ({ fullWidth, image }) => (
  <div className={!fullWidth && 'container'}>
    <ImageWrap fullWidth={fullWidth}>
      <img src={image.source_url} alt={image.alt_text} />
    </ImageWrap>
  </div>
);

export default imageBlock;
