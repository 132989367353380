import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import ContactButtons from '../homepage/contactButtons';

const LinkBlock = styled.div`
    width: 100%;

    @media (min-width: 768px) {
        display: flex;
        align-items: flex-end;
    }

    .linkblock-wrap {
        width: 100%;
        display: block;
        position: relative;
        @media (min-width: 798px) {
            flex: 0 0 calc(100% - 150px);
        }

        img {
            width: 100%;
            opacity: 0.24;
            transition: all 300ms;
        }

        &:hover,
        &:focus {
            img {
                opacity: 1;
            }
        }
    }

    .linkblock-textwrap {
        @media (max-width: 768px) {
            text-align: center;
        }

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 0 20px;

        @media (min-width: 768px) {
            padding: 0 50px;
        }

        @media (min-width: 992px) {
            top: 52%;
            max-width: 900px;
            margin: 0 auto;
        }
    }

    .linkblock-subtitle {
        color: white;
        line-height: 1.2;
    }

    .linkblock-title {
        color: white;
        font-family: ${props => props.theme.titleFont};
        font-size: 2rem;
        line-height: 1.2;

        @media (min-width: 768px) {
            font-size: 2.5rem;
        }

        @media (min-width: 992px) {
            font-size: 4rem;
        }
    }

    .linkblock-nav {
        @media (max-width: 768px) {
            display: none;
        }

        flex: 1;
        color: white;
        font-weight: 600;
        position: relative;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding-bottom: 55px;

        span {
            @media (max-width: 1200px) {
                display: none;
            }
            display: flex;
            align-items: center;
            position: absolute;

            left: 50%;
            bottom: 115px;
            flex: 1;
            width: 200px;
            transform: translateX(-50%) rotate(-90deg);
            transition: all 300ms;

            &::before {
                @media (max-width: 1200px) {
                    display: none;
                }
                content: '';
                width: 49px;
                height: 1px;
                background: white;
                margin-right: 30px;
                transition: all 300ms;
            }
        }

        &:hover,
        &:focus {
            span {
                &::before {
                    width: 97px;
                }
            }
        }
    }

    .linkblock-navwrap {
        height: 230px;
        position: relative;
    }
`;

const projectLink = ({ project: { node }, options }) => (
  <LinkBlock>
    <Link className="linkblock-wrap" to={node.path}>
      {node.acf.project_navigation_image && (
        <img
          src={node.acf.project_navigation_image.source_url}
          alt={node.acf.project_navigation_image.alt_text}
          className="linkblock-image"
        />
      )}
      <div className="linkblock-textwrap">
        <p className="linkblock-subtitle">Up Next</p>
        <h3 className="linkblock-title">{node.title}</h3>
      </div>
    </Link>
    <div className="linkblock-nav">
      <div className="linkblock-navwrap">
        <span>Let&apos;s chat</span>
      </div>
      <ContactButtons options={options} stacked="true" />
    </div>
  </LinkBlock>
);

export default projectLink;
