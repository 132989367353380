import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { element } from 'prop-types';

const Banner = styled.div`
    width: 100%;
    position: relative;
    z-index: 0;

    img {
        width: 100%;
    }

    .banner-controls {
        @media (max-width: 768px) {
            display: none;
        }

        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        display: flex;
        justify-content: space-between;

        &.__noprev {
            justify-content: flex-end;
        }
    }

    .banner-next,
    .banner-prev {
        color: white;
        font-weight: 600;
        position: relative;
        transition: all 800ms;
        transition-delay: 1000ms;
        opacity: ${props => (props.pageIn ? '1' : '0')};

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0;
            height: 1px;
            background: white;
            transition: all 300ms ${props => props.theme.cubic};
        }

        &:hover,
        &:focus {
            &::after {
                width: 100%;
                right: auto;
                left: 0;
            }
        }
    }

    .banner-prev {
        transform: rotate(90deg) translateY(20px) ${props => (props.pageIn ? 'translateX(0)' : 'translateX(-30px)')};
    }

    .banner-next {
        transform: rotate(-90deg) translateY(0px) ${props => (props.pageIn ? 'translateX(0)' : 'translateX(30px)')};
        justify-self: flex-end;
    }

    .banner-wrap {
        position: relative;
        @media (min-width: 768px) {
        }
    }

    .banner-title {
        text-align: center;
        color: white;
        font-family: ${props => props.theme.titleFont};
        font-size: 2rem;
        font-weight: 400;
        line-height: 1.2;
        max-width: 90%;
        margin: 40px auto 0 auto;
        display: block;
        transition: all 600ms;
        transition-delay: 900ms;
        transform: ${props => (props.pageIn ? 'translateY(0)' : 'translateY(-30px)')};
        opacity: ${props => (props.pageIn ? '1' : '0')};

        @media (min-width: 450px) {
            font-size: 2.5rem;
        }

        @media (min-width: 600px) {
            font-size: 3rem;
        }

        @media (min-width: 768px) {
            width: 100%;
            max-width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: ${props => (props.pageIn ? 'translate(-50%, -50%)' : 'translate(-50%, calc(-50% - 50px))')};
            margin: 0;
            font-size: 3.5rem;
            line-height: 1.1;
        }

        @media (min-width: 992px) {
            font-size: 5rem;
        }

        @media (min-width: 1200px) {
            font-size: 6.05rem;
            letter-spacing: 1.2px;
        }
    }

    .banner-scroll {
        color: white;
        font-weight: 600;
        position: absolute;
        left: 50%;
        bottom: -35px;
        transition: all 300ms;
        transition-delay: 1150ms;
        opacity: ${props => (props.pageIn ? '1' : '0')};
        transform: translateX(-50%) ${props => (props.pageIn ? 'translateY(0)' : 'translateY(-30px)')};
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 768px) {
            display: none;
        }

        &::after {
            content: '';
            width: 1px;
            height: 67px;
            background: white;
            margin-top: 25px;
            transition: all 300ms ${props => props.theme.cubic};
        }

        &:hover,
        &:focus {
            &::after {
                height: 117px;
            }
        }
    }
`;

const projectBanner = ({
  pageIn, bannerImage, bannerTitle, previousProject, nextProject,
}) => (
  <Banner pageIn={pageIn}>
    <img src={bannerImage.source_url} alt={bannerImage.alt_text_text} />
    <h1 className="banner-title" dangerouslySetInnerHTML={{ __html: bannerTitle }} />

    <div className={`banner-controls ${!previousProject && '__noprev'}`}>
      {previousProject && (
      <Link to={previousProject.node.path} className="banner-prev">
						Previous Project
      </Link>
      )}
      {nextProject && (
      <Link to={nextProject.node.path} className="banner-next">
						Next Project
      </Link>
      )}
    </div>

    <button
      className="banner-scroll"
      onClick={() => {
        const flexibleBlocks = document.getElementById('flexibleBlocks');
        flexibleBlocks.scrollIntoView({ behavior: 'smooth' });
      }}
      type="button"
    >
Scroll

    </button>
  </Banner>
);

export default projectBanner;
